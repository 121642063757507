import React, { useState, useEffect } from "react";
import ResultQuiz from "../ResultQuiz/ResultQuiz";
import { AiOutlineInfo } from "react-icons/ai";
import Tooltip from "../../IndependentQuizs/Tooltip";

function Dogs() {
  const urlBarfDogs =
    "https://wildbalancemascotas.myshopify.com/collections/barf-perros";

    const [petName, setPetName] = useState("");
    const [age, setAge] = useState("");
    const [weight, setWeight] = useState();
    const [activity, setActivity] = useState();
    const [foodType, setfoodType] = useState("");
    const [showResult, setShowResult] = useState(false);
    const [showTooltip, setShowTooltip] = useState();

  useEffect(() => {
    // Actualizar el componente ResultQuiz cuando cambie el valor del peso
    if (showResult) {
      setShowResult();
    }
  }, [weight]);

  let totalResult;

  const redirection =
    "https://wildbalancemascotas.myshopify.com/products/pack-iniciacion-barf-para-perros";

  const awnserError = "Ups, intenta que el peso sea lo más aproximado posible";
  const gramsPerBurger = 50;
  const gramsPerCooked = 325;
  const eurosPerBurger = 0.33;
  let onclickfunctions;
  let calcAgain = () => {
    setPetName("");
    setAge(false);
    setWeight(false);
    setActivity(false);
    setfoodType(false);
    setShowResult(false);
};

  const tooltip = () => {
    if (showTooltip !== false) {
      setShowTooltip(false);
    } else {
      setShowTooltip(<Tooltip />);
    }
  };

  function validateWeight() {
    if (weight === "") {
      return null;
    } else if (age === "baby0" && (weight < 0.01 || weight > 60)) {
      return <p className="validationPar">{awnserError}</p>;
    } else if (age === "baby4" && (weight < 0.01 || weight > 300)) {
      return <p className="validationPar">{awnserError}</p>;
    } else if (age === "baby6" && (weight < 0.01 || weight > 450)) {
      return <p className="validationPar">{awnserError}</p>;
    } else if (age === "baby10" && (weight < 0.01 || weight > 600)) {
      return <p className="validationPar">{awnserError}</p>;
    } else if (age === "adult" && (weight < 0.01 || weight > 800)) {
      return <p className="validationPar">{awnserError}</p>;
    }
    return null;
  }

  function getGramsForDogs(age, weight, activity) {
    weight = weight * 1000; // grams
    if (age !== "adult") {
      activity = "base";
    }
    
    let DOG_FACTORS_BURGERS = {
      baby0: {
        base: {
          min_posible_value: 50,
          max_posible_value: 900,
          values: [
            { min: 10, max: 500, value: 0.1 },
            { min: 500, max: 4000, value: 0.1 },
            { min: 4000, max: 7000, value: 0.09 },
            { min: 7000, max: 10000, value: 0.08 },
            { min: 10000, max: 12000, value: 0.07 },
            { min: 12000, max: 15000, value: 0.06 },
            { min: 15000, max: 60000, value: 0.05 },
          ],
        },
      },
      baby4: {
        base: {
          min_posible_value: 60,
          max_posible_value: 1125,
          values: [
            { min: 10, max: 1000, value: 0.07 },
            { min: 1000, max: 11000, value: 0.06 },
            { min: 11000, max: 16000, value: 0.05 },
            { min: 16000, max: 21000, value: 0.04 },
            { min: 21000, max: 30000, value: 0.04 },
            { min: 30000, max: 300000, value: 0.035 },
          ],
        },
      },
      baby6: {
        base: {
          min_posible_value: 50,
          max_posible_value: 1237.5,
          values: [
            { min: 10, max: 1000, value: 0.06 },
            { min: 1000, max: 4000, value: 0.05 },
            { min: 4000, max: 5000, value: 0.04 },
            { min: 5000, max: 18000, value: 0.04 },
            { min: 18000, max: 26000, value: 0.04 },
            { min: 26000, max: 36000, value: 0.04 },
            { min: 36000, max: 45000, value: 0.04 },
            { min: 45000, max: 450000, value: 0.03 },
          ],
        },
      },
      baby10: {
        base: {
          min_posible_value: 50,
          max_posible_value: 1200,
          values: [
            { min: 10, max: 1000, value: 0.06 },
            { min: 1000, max: 4000, value: 0.04 },
            { min: 4000, max: 7000, value: 0.03 },
            { min: 7000, max: 9000, value: 0.03 },
            { min: 9000, max: 26000, value: 0.025 },
            { min: 26000, max: 50000, value: 0.025 },
            { min: 50000, max: 60000, value: 0.0225 },
            { min: 60000, max: 600000, value: 0.02 },
          ],
        },
      },
      adult: {
        senior: {
          min_posible_value: 50,
          max_posible_value: 1200,
          values: [
            { min: 10, max: 1000, value: 0.055 },
            { min: 1000, max: 2000, value: 0.05 },
            { min: 2000, max: 3000, value: 0.045 },
            { min: 3000, max: 4000, value: 0.04 },
            { min: 4000, max: 6000, value: 0.03 },
            { min: 6000, max: 7000, value: 0.0275 },
            { min: 7000, max: 8000, value: 0.025 },
            { min: 8000, max: 21000, value: 0.02 },
            { min: 21000, max: 31000, value: 0.0175 },
            { min: 31000, max: 80000, value: 0.015 },
            { min: 80000, max: 800000, value: 0.012 },
          ],
        },
        normal: {
          min_posible_value: 50,
          max_posible_value: 1400,
          values: [
            { min: 10, max: 1000, value: 0.06 },
            { min: 1000, max: 2000, value: 0.05 },
            { min: 2000, max: 3000, value: 0.05 },
            { min: 3000, max: 4000, value: 0.045 },
            { min: 4000, max: 5000, value: 0.04 },
            { min: 5000, max: 7000, value: 0.03 },
            { min: 7000, max: 8000, value: 0.0275 },
            { min: 8000, max: 9000, value: 0.025 },
            { min: 9000, max: 21000, value: 0.0225 },
            { min: 21000, max: 31000, value: 0.02 },
            { min: 31000, max: 80000, value: 0.0175 },
            { min: 80000, max: 800000, value: 0.016 },
          ],
        },
        high: {
          min_posible_value: 60,
          max_posible_value: 1600,
          values: [
            { min: 10, max: 1000, value: 0.07 },
            { min: 1000, max: 3000, value: 0.06 },
            { min: 3000, max: 4000, value: 0.05 },
            { min: 4000, max: 5000, value: 0.04 },
            { min: 5000, max: 9000, value: 0.035 },
            { min: 9000, max: 21000, value: 0.03 },
            { min: 21000, max: 31000, value: 0.025 },
            { min: 31000, max: 80000, value: 0.025 },
            { min: 80000, max: 800000, value: 0.02 },
          ],
        },
      },
    };
    
    let DOG_FACTORS_COOKED_FOOD = {
      baby0: {
        base: {
          min_posible_value: 50,
          max_posible_value: 900,
          values: [
            { min: 10, max: 500, value: 0.1 },
            { min: 500, max: 4000, value: 0.1 },
            { min: 4000, max: 7000, value: 0.09 },
            { min: 7000, max: 10000, value: 0.08 },
            { min: 10000, max: 12000, value: 0.07 },
            { min: 12000, max: 15000, value: 0.06 },
            { min: 15000, max: 60000, value: 0.05 },
          ],
        },
      },
      baby4: {
        base: {
          min_posible_value: 60,
          max_posible_value: 1125,
          values: [
            { min: 10, max: 1000, value: 0.07 },
            { min: 1000, max: 11000, value: 0.06 },
            { min: 11000, max: 16000, value: 0.05 },
            { min: 16000, max: 21000, value: 0.04 },
            { min: 21000, max: 30000, value: 0.04 },
            { min: 30000, max: 300000, value: 0.035 },
          ],
        },
      },
      baby6: {
        base: {
          min_posible_value: 50,
          max_posible_value: 1237.5,
          values: [
            { min: 10, max: 1000, value: 0.07 },
            { min: 1000, max: 4000, value: 0.06 },
            { min: 4000, max: 7000, value: 0.05 },
            { min: 7000, max: 9000, value: 0.04 },
            { min: 9000, max: 26000, value: 0.04 },
            { min: 26000, max: 50000, value: 0.04 },
            { min: 50000, max: 60000, value: 0.04 },
            { min: 60000, max: 600000, value: 0.03 },
          ],
        },
      },
      baby10: {
        base: {
          min_posible_value: 50,
          max_posible_value: 1200,
          values: [
            { min: 10, max: 1000, value: 0.07 },
            { min: 1000, max: 4000, value: 0.06 },
            { min: 4000, max: 7000, value: 0.05 },
            { min: 7000, max: 9000, value: 0.04 },
            { min: 9000, max: 26000, value: 0.03 },
            { min: 26000, max: 50000, value: 0.03 },
            { min: 50000, max: 60000, value: 0.025 },
            { min: 60000, max: 600000, value: 0.025 },
          ],
        },
      },
      adult: {
        senior: {
          min_posible_value: 50,
          max_posible_value: 1200,
          values: [
            { min: 10, max: 1000, value: 0.065 },
            { min: 1000, max: 2000, value: 0.06 },
            { min: 2000, max: 3000, value: 0.055 },
            { min: 3000, max: 4000, value: 0.05 },
            { min: 4000, max: 6000, value: 0.04 },
            { min: 6000, max: 7000, value: 0.03 },
            { min: 7000, max: 8000, value: 0.035 },
            { min: 8000, max: 21000, value: 0.035 },
            { min: 21000, max: 31000, value: 0.03 },
            { min: 31000, max: 80000, value: 0.025 },
            { min: 80000, max: 800000, value: 0.02 },
          ],
        },
        normal: {
          min_posible_value: 50,
          max_posible_value: 1400,
          values: [
            { min: 10, max: 1000, value: 0.065 },
            { min: 1000, max: 2000, value: 0.06 },
            { min: 2000, max: 3000, value: 0.055 },
            { min: 3000, max: 4000, value: 0.05 },
            { min: 4000, max: 6000, value: 0.04 },
            { min: 6000, max: 7000, value: 0.03 },
            { min: 7000, max: 8000, value: 0.035 },
            { min: 8000, max: 21000, value: 0.035 },
            { min: 21000, max: 31000, value: 0.03 },
            { min: 31000, max: 80000, value: 0.025 },
            { min: 80000, max: 800000, value: 0.02 },
          ],
        },
        high: {
          min_posible_value: 60,
          max_posible_value: 1600,
          values: [
            { min: 10, max: 1000, value: 0.065 },
            { min: 1000, max: 2000, value: 0.06 },
            { min: 2000, max: 3000, value: 0.055 },
            { min: 3000, max: 4000, value: 0.05 },
            { min: 4000, max: 6000, value: 0.04 },
            { min: 6000, max: 7000, value: 0.03 },
            { min: 7000, max: 8000, value: 0.035 },
            { min: 8000, max: 21000, value: 0.035 },
            { min: 21000, max: 31000, value: 0.03 },
            { min: 31000, max: 80000, value: 0.025 },
            { min: 80000, max: 800000, value: 0.02 },
          ],
        },
      },
    };
    

  let factors = foodType === "burgers" ? DOG_FACTORS_BURGERS : DOG_FACTORS_COOKED_FOOD;
  let gramsPerFood = foodType === "burgers" ? gramsPerBurger : gramsPerCooked;


    let factor_values = factors[age][activity]["values"];
    let factor_value = factor_values.find(
      (factor) => factor["min"] <= weight && factor["max"] >= weight
    );
    let factor = factor_value["value"];
    let grams = Math.round(weight * factor);

    let foodUnits = Math.round(grams / gramsPerFood);
    let daily_euros = foodUnits * eurosPerBurger;
    let month_euros = daily_euros * 31;
    return {
      grams: grams,
      foodUnits: foodUnits,
      foodType: foodType,
      dailyEuros: daily_euros.toFixed(2),
      monthEuros: month_euros.toFixed(2),
    };
  }
  return (
    <section className="containerPet">
      <section className="inputsPet">
        <input
          name="name"
          type="text"
          placeholder="Nombre de tu perrito"
          onChange={(e) => setPetName(e.target.value)}
          value={petName}
        />

        <article className="ageCard">
          <select
            name="age"
            id="age"
            onChange={(e) => setAge(e.target.value)}
            value={age}
          >
            <option disabled={true} selected value="">Edad</option>
            <option id="baby0" value="baby0"> Destete hasta 3 meses </option>
            <option value="baby4">4 meses a 6 meses</option>
            <option value="baby6">7 meses a 9 meses</option>
            <option value="baby10">10 meses a 11 meses</option>
            <option value="adult">Adulto (12 meses o mas)</option>
          </select>
        </article>

        <article className="weightCard">
          <input
            name="weight"
            type="number"
            placeholder="Peso"
            onChange={(e) => setWeight(e.target.value)}
            value={weight}
            min="0.01"
            max="40"
            required
          />
          <div className="answerErrorPa">{validateWeight()}</div>
        </article>


        <article className="foodTypeCard">
          <select
            name="foodType"
            id="foodType"
            onChange={(e) => setfoodType(e.target.value)}
            value={foodType}
          >
            <option disabled={true} selected value="">¿Qué quieres que coma?</option>
            <option value="burgers">Barf</option>
            <option value="cooked">Comida cocinada</option>
          </select>
        </article>


        <select
          name="activity"
          id="activity"
          placeholder="Nivel de actividad"
          onChange={(e) => setActivity(e.target.value)}
          defaultValue="Elige"
          value={activity}
        >
          <option selected>Actividad</option>
          <option value="senior">Castrados, sedentarios o abuelitos</option>
          <option value="normal">Normal</option>
          <option value="high">Mucha</option>
        </select>
      </section>

      {/* <section className="btnBox">
        <button
          className="btn calc"
          onClick={
            (onclickfunctions = () => {
              let totalResult = getGramsForDogs(age, weight, activity);
              // reDirect();
              // window.top.location.href =
              //   redirection +
              //   "?name=" +
              //   petName +
              //   "&burgers=" +
              //   totalResult["burgers"] +
              //   "&dailyEuros=" +
              //   totalResult["dailyEuros"] +
              //   "&monthEuros=" +
              //   totalResult["monthEuros"] +
              //   "&grams=" +
              //   totalResult["grams"];
            })
          }
        >
          Calcular
        </button>

        <button className="tooltip" onClick={(e) => tooltip()}>
          <AiOutlineInfo />
        </button>

        <section className="infoTooltip">{showTooltip}</section>
      </section> */}

      {showResult}

      {showResult ? (
        <>
          <button className="btn reset" onClick={calcAgain}>
            Resetear
          </button>
        </>
      ) : (
        <section className="btnBox">
          <button
            className="btn calc"
            onClick={
              (totalResult = () => {
                setShowResult(
                  <ResultQuiz
                    name={petName}
                    funct={getGramsForDogs(age, weight, activity)}
                    redirection={
                      "https://wildbalancemascotas.myshopify.com/products/pack-iniciacion-barf-para-perros"
                    }
                    foodType={foodType} // boton pasa valor
                  />
                );
              })
            }
          >
            Calcular
          </button>
          <button className="tooltip" onClick={(e) => tooltip()}>
            <AiOutlineInfo />
          </button>
          <section className="infoTooltip">{showTooltip}</section>
        </section>
      )}

      <button className="btn calc goShop">
        <a href="https://tiendas.wildbalance.es/" target="_top">
          Ir a la tienda
        </a>
      </button>
    </section>
  );
}
export default Dogs;
